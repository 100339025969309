<template>
  <v-container
    fluid
    fill-height
    class="pa-0 ma-0 background"
    style="display: grid; grid-template-columns: 74% 26%"
  >
    <div
      class="d-flex flex-column full-width align-center"
      style="row-gap: 20px"
    >
      <img :src="logo" :width="150" style="border-radius: 50%" />
      <slot />
    </div>

    <v-img
      v-if="$vuetify.breakpoint.lgAndUp"
      :src="authBg"
      style="height: 100%"
    />
  </v-container>
</template>

<script>
import logo from '@/assets/corz-logo.svg';
import login from '@/assets/illustrations/login.svg';
import auth from '@/assets/illustrations/auth.svg';
import authBg from '@/assets/auth-bg.jpg';
export default {
  name: 'AuthLayout',
  props: {
    image: {
      type: String,
      default: login
    }
  },
  created() {},
  data() {
    return {
      logo,
      auth,
      authBg
    };
  }
  // computed: {
  //   image() {
  //     switch (this.$route.name) {
  //       case 'verification':
  //         return verify;
  //       case 'login':
  //         return login;
  //       case 'signup':
  //         return signup;
  //     }
  //   }
  // }
};
</script>

<style lang="scss" scoped>
.bg {
  background-image: url('../assets/background-pattern.jpg');
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.fields-container {
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.bg-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  max-width: 850px;
}
</style>
